import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { getYearMonthsFromToday } from "../../Utilities/time";

interface TransactionFees {
  date: string;
  v: number;
}

interface BurnChartProps {
  data: {
    transactionFees: TransactionFees[];
  };
}

const createChartOptions = (transactionFees: TransactionFees[]): any => {
  const plotData = [...transactionFees].reverse().slice(-24);

  const formattedDates = getYearMonthsFromToday(plotData.length).reverse();

  return {
    chart: {
      height: "300px",
      type: "column",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: formattedDates,
      lineWidth: 0,
      minorGridLineWidth: 0,
      tickLength: 0,
    },
    yAxis: {
      title: {
        text: "CELO",
        style: {
          color: "#9E9A97",
        },
      },
      gridLineDashStyle: "LongDash",
    },
    series: [
      {
        name: "Fee",
        color: "#DAD1C9",
        lineWidth: 2,
        data: plotData.map((d) => Math.ceil(d.v)),
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, Highcharts.color("#DAD1C9").setOpacity(0.42).get("rgba")],
            [1, Highcharts.color("#DAD1C9").setOpacity(0).get("rgba")],
          ],
        },
        threshold: null,
        marker: {
          enabled: false,
        },
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

export const CeloDailyTxFeeChart = ({ data }: BurnChartProps): JSX.Element => (
  <HighchartsReact
    highcharts={Highcharts}
    options={createChartOptions(data.transactionFees)}
  />
);
