import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type OffsetFundingSourceType = {
  data: Array<{ date: string; source: string; v: number; v_usd: number }>;
};

const url = "/offset_funding_source";

export const useOffsetFundingSource = (): Array<{
  date: string;
  source: string;
  v: number;
  v_usd: number;
}> => {
  const { data } = useSWR<OffsetFundingSourceType>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(1000),
  });

  return data?.data!;
};
