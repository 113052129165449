const CeloColors = ["#7CC0FF", "#56DF7C", "#E7E3D4", "#1E002B"];
const CeloColorsFaded = [
  "rgba(190, 224, 255, 1)",
  "#56df7c42",
  "#f8f7f2",
  "#1E002B",
];

// Mapping objects
const colorMapping: { [key: string]: string } = {
  toucan: CeloColors[0],
  wren: CeloColors[1],
};

const colorMappingFaded: { [key: string]: string } = {
  toucan: CeloColorsFaded[0],
  wren: CeloColorsFaded[1],
};

// Default color
const defaultColor = "#000000"; // You can set any default color here

// Function to get color by string
export function getColorByString(
  projectName: string,
  shade: "normal" | "faded" = "normal"
) {
  const mapping = shade === "normal" ? colorMapping : colorMappingFaded;

  // Check if the input string exists in the mapping object
  if (mapping.hasOwnProperty(projectName)) {
    // Return the corresponding color
    return mapping[projectName];
  }

  // If the input string is not found in the mapping, return the default color
  return defaultColor;
}
