import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";
import { fetchJsonSwr } from "./Fetchers";

export type GasPriceAndEpoch = {
  data: {
    gas_price: number;
    epoch: number;
  };
};

const url = "/gas_price_and_epoch";

export const useGasPriceAndEpoch = (): { gasPrice: number; epoch: number } => {
  const { data } = useSWR<GasPriceAndEpoch>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  return { gasPrice: data?.data.gas_price, epoch: data?.data.epoch };
};
