import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

import { CardHeaderWithToolTip } from "../CardHeader";

import Ecosapiens from "../../assets/CelosapienLevel4Optimized.webp";

export function PartnerSection() {
  return (
    <>
      <Typography variant="h1" color={"#428CFC"}>
        #Carbon Offsetting NFT 🤝
      </Typography>
      <Typography variant="h2">Celo ecosystem at work</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={5} container alignItems="stretch">
          <Card
            sx={{
              height: "auto",
              borderRadius: 2,
              backgroundColor: "#FFFCFA",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <CardContent>
                <CardHeaderWithToolTip
                  header={"Celosapien"}
                  tooltip={"Celosapien ERC721 Contract."}
                  link={
                    "https://celoscan.io/token/0xee0fd56292c1d250e3a4cda7d21ff4e6ecf6811f?a=0x530cf34ee4977d375493d29fb9ce194f138b4693"
                  }
                />
              </CardContent>
            </Box>
            <Box
              sx={{
                borderTop: 1,
                borderColor: "divider",
                pt: 3,
                pb: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  width: "49%",
                  borderRadius: 2,
                  boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.5)",
                }}
                alt="Ecosapiens NFT."
                src={Ecosapiens}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item container xs={12} lg={7} spacing={4}>
          <Grid item xs={12}>
            <Card
              sx={{
                height: "100%",
                borderRadius: 3,
                backgroundColor: "#FFFCFA",
              }}
            >
              <CardContent>
                <Typography variant={"h3"}>
                  Mechanics
                </Typography>
              </CardContent>
              <CardContent>
                <Typography component="span">
                  The Celosapien is a 1-of-1 dynamic NFT piece
                  commissioned for the Celo ecosystem from{" "}
                  <a
                    href="https://www.ecosapiens.xyz"
                    style={{ color: "grey" }}
                  >
                    Ecosapiens
                  </a>
                  , a leading carbon NFT project.  The
                  Celosapien’s characteristics evolve
                  dynamically, through 7 levels, as it
                  facilitates additional carbon offsets on
                  behalf of the Celo ecosystem.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                height: "100%",
                borderRadius: 3,
                backgroundColor: "#FFFCFA",
              }}
            >
              <CardContent>
                <Typography variant={"h3"}>
                  Artist statement by Garret Kane
                </Typography>
              </CardContent>
              <CardContent>
                <Typography>
                  The Celosapien combines visuals native to
                  mangrove restoration and Kenyan grassland
                  protection. Beginning in an arid desert
                  scape, the Celosapien undergoes
                  hyper-cellular mitosis. It interfaces with
                  its environment, decodes the damage, and
                  evolves carbon-absorbing flora. Looking
                  outward, it draws water from the atmosphere
                  through selenite crystals and deposits it
                  into the ground. From this, new trophic webs
                  and evolutionary pathways form as the
                  ecosystem heals and strengthens—reforesting
                  the once-dead land into a lush rainforest
                  full of biodiversity and rich with life.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
