import React from "react";
import {
  Grid,
  Paper,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GitHubIcon from "@mui/icons-material/GitHub";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import LocalGasStationTwoToneIcon from "@mui/icons-material/LocalGasStationTwoTone";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import CeloTokenSymbol from "../../assets/CeloTokenSymbol.svg";
import DiscordIcon from "../../assets/DiscordIcon.svg";
import { useCeloUSDRate } from "../../api/CeloUSDRate";
import { useGasPriceAndEpoch } from "../../api/GasPriceAndEpoch";

function Header() {
  const theme = useTheme();
  const celoRate = useCeloUSDRate();
  const gasPriceAndEpoch = useGasPriceAndEpoch();

  const isMobile = useMediaQuery("(max-width:1200px)");

  return (
    <Grid container component="header">
      <Grid item xs={12} sm={4}>
        <Paper
          elevation={2}
          sx={{
            p: 0.5,
            borderRadius: 1,
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
            wordWrap: "break-word",
            display: "inline-flex",
          }}
        >
          <Stack
            direction="row"
            spacing={0.5}
            alignItems="center"
            sx={{ flexGrow: 1 }}
          >
            <Box sx={{ height: 17, width: 17, color: "#476520" }}>
              <img src={CeloTokenSymbol} alt="Celo Icon" />
            </Box>
            <Typography variant="subtitle1" sx={{ pr: 1 }}>
              {celoRate?.toFixed(2) ?? ".."} USD
            </Typography>
            <LocalGasStationTwoToneIcon
              sx={{ color: "#476520", height: 21, width: 21 }}
            />
            <Typography variant="subtitle1" sx={{ pr: 1 }}>
              {Math.round(gasPriceAndEpoch.gasPrice / 10 ** 9) ?? ".."} Gwei
            </Typography>
            <QueryBuilderTwoToneIcon
              sx={{ color: "#476520", height: 20, width: 20 }}
            />
            <Typography variant="subtitle1" sx={{ pr: 1 }}>
              Epoch: #{gasPriceAndEpoch?.epoch ?? ".."}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      {!isMobile && (
        <Grid
          item
          container
          xs={12}
          sm={8}
          spacing={2}
          justifyContent="flex-end"
          textAlign="center"
          alignItems="center"
        >
          <>
            <Grid item xs={2} color="secondary.main">
              <a
                href="https://explorer.celo.org/mainnet/"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                Celo Explorer
              </a>
            </Grid>
            <Grid item xs={1} color="secondary.main">
              <a
                href="https://clabs.co"
                style={{
                  textDecoration: "none",
                  color: theme.palette.secondary.main,
                }}
              >
                cLabs
              </a>
            </Grid>
            <Grid item xs={1}>
              <a href="https://t.me/celooffical">
                <TelegramIcon style={{ color: theme.palette.secondary.main }} />
              </a>
            </Grid>
            <Grid item xs={1}>
              <a href="https://twitter.com/CeloOrg">
                <TwitterIcon style={{ color: theme.palette.secondary.main }} />
              </a>
            </Grid>
            <Grid item xs={1}>
              <a href={"https://github.com/celo-org"}>
                <GitHubIcon style={{ color: theme.palette.secondary.main }} />
              </a>
            </Grid>
            <Grid item xs={1}>
              <a href="https://discord.gg/celo">
                <img
                  src={DiscordIcon}
                  alt="Discord Icon"
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                href={"https://clabs.co/careers"}
                sx={{
                  backgroundColor: "primary.main",
                  color: "#fff",
                  fontStyle: "Inter",
                  textTransform: "none",
                }}
              >
                Join us
              </Button>
            </Grid>
          </>
        </Grid>
      )}
    </Grid>
  );
}

export default Header;
