import React from "react";
import { Box, Typography } from "@mui/material";
import { ToolTip } from "./ToolTip";

interface Props {
  header: string;
  tooltip: string;
  link?: string;
}

export const CardHeaderWithToolTip: React.FC<Props> = ({
  header,
  tooltip,
  link,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h3" mr={1}>
          {header}
        </Typography>
      </Box>
      <Box
        sx={{
          alignSelf: "start",
          display: "flex",
          alignItems: "center",
          mt: 0.33,
        }}
      >
        <ToolTip tip={tooltip} link={link} />
      </Box>
    </Box>
  );
};
