import React from "react";
import { Box, Typography, Grid } from "@mui/material";

import DiscordIcon from "../../assets/DiscordIcon.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";

export function ContactSection() {
  return (
    <Box>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h1" color="primary.main" align="center">
            Contact
          </Typography>
        </Grid>
        <Box>
          <Grid item xs={12} pb={1}>
            <a href={"https://github.com/celo-org"}>
              <Box display="inline-block" mr={2}>
                <GitHubIcon
                  sx={{
                    color: "secondary.main",
                    verticalAlign: "middle",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Box>
              <Typography
                variant="body2"
                color="secondary.main"
                display="inline-block"
              >
                Build With Celo
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} pb={1}>
            <a href="https://discord.gg/celo">
              <Box display="inline-block" mr={2}>
                <img
                  src={DiscordIcon}
                  alt="Discord Icon"
                  style={{
                    verticalAlign: "middle",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Box>
              <Typography
                variant="body2"
                color="secondary.main"
                display="inline-block"
              >
                Join the Community
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} pb={1}>
            <a href="https://twitter.com/CeloOrg">
              <Box display="inline-block" mr={2}>
                <TwitterIcon
                  sx={{
                    color: "secondary.main",
                    verticalAlign: "middle",
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Box>
              <Typography
                variant="body2"
                color="secondary.main"
                display="inline-block"
              >
                Key Announcements
              </Typography>
            </a>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
