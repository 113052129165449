import { getDomain } from "../config";

export type FetchError = {
  type: "FetchError";
  error: Error;
};

export type ApiError = {
  type: "ApiError";
  error: Error;
};

export type ApiResult<A> = { data: A } | FetchError | ApiError;

export const fetchApiJson = <A>(url: string): Promise<ApiResult<A>> =>
  fetchApiJsonShared(getDomain(), url);
export const fetchJsonSwr = async <A>(url: string): Promise<A> => {
  const dataOrError = await fetchApiJson<A>(url);
  if ("data" in dataOrError) {
    return dataOrError.data;
  } else {
    throw dataOrError.error;
  }
};

export const absoluteUrlFromUrl = (domain: string, url: string): string =>
  typeof url === "string" && url.startsWith("https://")
    ? url
    : `${domain}${url}`;

type SafeResponse =
  | {
      type: "Response";
      res: Response;
    }
  | FetchError;

const fetchSafe = async (url: RequestInfo): Promise<SafeResponse> => {
  try {
    const res = await fetch(url);
    return { res, type: "Response" };
  } catch (error) {
    if (error instanceof Error) {
      return { error, type: "FetchError" };
    }

    return { error: new Error(String(error)), type: "FetchError" };
  }
};

/** Fetch a JSON API response, and return the data, or an error. */
const fetchApiJsonShared = async <A>(
  apiDomain: string,
  url: string
): Promise<ApiResult<A>> => {
  const absoluteUrl = absoluteUrlFromUrl(apiDomain, url);
  const safeRes = await fetchSafe(absoluteUrl);
  if (safeRes.type === "FetchError") {
    return safeRes;
  }

  const res = safeRes.res;

  if (res.status === 200) {
    const body = (await res.json()) as A;
    return { data: body };
  }

  try {
    const body = (await res.json()) as {
      message?: string;
      msg?: string;
    };

    const message = body?.message || body?.msg;

    if (typeof message === "string") {
      const error = new Error(
        `failed to fetch ${absoluteUrl}, status: ${res.status}, message: ${message}`
      );
      return { error, type: "ApiError" };
    }

    const error = new Error(
      `failed to fetch ${absoluteUrl}, status: ${res.status}, json body, but no message, logging body.`
    );
    console.error(body);
    return { error, type: "ApiError" };
  } catch {
    const error = new Error(
      `failed to fetch ${absoluteUrl}, status: ${res.status}, no body.`
    );
    return { error, type: "ApiError" };
  }
};
