import React from "react";
import { Link, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SxProps } from "@mui/system";

interface ToolTipProps {
  tip: string | string[];
  sx?: SxProps;
  link?: string;
}

export function ToolTip({ tip, sx, link }: ToolTipProps) {
  const tipContent =
    typeof tip === "string" ? (
      link ? (
        <Link href={link} color="textSecondary">
          <Typography sx={{ p: 1, color: "white" }}>{tip}</Typography>
        </Link>
      ) : (
        <Typography sx={{ p: 1, color: "white" }}>{tip}</Typography>
      )
    ) : (
      tip.map((text, index) => (
        <Typography key={index} sx={{ p: 1, color: "white" }}>
          {text}
        </Typography>
      ))
    );

  return (
    <Tooltip title={tipContent} arrow placement="top" sx={sx}>
      <InfoOutlinedIcon style={{ color: "#9E9A97" }} />
    </Tooltip>
  );
}
