import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";
import { fetchJsonSwr } from "./Fetchers";

export type CeloUSDRate = {
  data: number;
};

const url = "/celo_usd_rate";

export const useCeloUSDRate = (): number => {
  const { data } = useSWR<CeloUSDRate>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  return data?.data!;
};
