import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Typography } from "@mui/material";

import { getYearMonthsFromToday } from "../../Utilities/time";

export const OffsetVsFootprintLegend = () => {
  return (
    <>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={14} height={14} bgcolor="#FF9A51" borderRadius={1} mr={1} />
        <Typography variant="subtitle2">Footprint</Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={14} height={14} bgcolor="#56DF7C" borderRadius={1} mr={1} />
        <Typography variant="subtitle2">Offset</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box width={14} height={14} bgcolor="#B490FF" borderRadius={1} mr={1} />
        <Typography variant="subtitle2">Net Carbon Footprint</Typography>
      </Box>
    </>
  );
};

interface ChartData {
  date: string;
  v: number;
}

interface OffsetData {
  date: string;
  total_offset: number;
}

interface OffsetVsFootprintData {
  offset: OffsetData[];
  footprint: ChartData[];
}

const OffsetVsFootprintOptions = (chartData: OffsetVsFootprintData): any => {
  const offsetData = chartData.offset.map((d) =>
    Math.round(d.total_offset * -1)
  );

  const footprintData = chartData.footprint.map((d) => Math.round(d.v));

  const longestDataLength = Math.max(offsetData.length, footprintData.length);

  const averageData = Array.from({ length: longestDataLength }, (_, i) =>
    Math.ceil(footprintData[i] - (offsetData[i] ?? 0) * -1)
  );

  const formattedDates = getYearMonthsFromToday(longestDataLength);

  return {
    chart: {
      type: "area",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: formattedDates,
      lineWidth: 0,
      minPadding: 0,
      maxPadding: 0,
      endOnTick: true,
      tickWidth: 0,
      reversed: true,
    },
    yAxis: {
      title: {
        text: "tonnes CO2",
      },
      tickInterval: 1000,
      gridLineDashStyle: "LongDash",
      plotLines: [
        {
          color: "#EADDD3",
          width: 3,
          value: 0, // draw line at y=0
        },
      ],
    },
    series: [
      {
        name: "Footprint",
        data: footprintData,
        type: "area",
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        color: "#FF9A51",
        fillColor: {
          linearGradient: [0, 0, 0, 80],
          stops: [
            [0, "#FF9A51"],
            [1, Highcharts.color("white").setOpacity(0).get("rgba")],
          ],
        },
      },
      {
        name: "Offset",
        type: "area",
        data: offsetData,
        lineWidth: 4,
        marker: {
          enabled: false,
        },
        color: "#56DF7C",
        fillColor: {
          linearGradient: [10, 500, 0, 0],
          stops: [
            [0, "#56DF7C"],
            [1, Highcharts.color("white").setOpacity(0).get("rgba")],
          ],
        },
      },
      {
        name: "Net Carbon Footprint",
        type: "line",
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        color: "#B490FF",
        data: averageData,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

export const OffsetVsFootprintChart = (
  chartData: OffsetVsFootprintData
): JSX.Element => (
  <HighchartsReact
    highcharts={Highcharts}
    options={OffsetVsFootprintOptions(chartData)}
  />
);
