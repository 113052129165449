import React, { useMemo } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";

import { TransactionsTable } from "../Tables/TransactionsTable";
import { WorldMap } from "../Maps/WorldMap";
import { useOffsetByCountry } from "../../api/OffsetByCountry";
import { useOffset } from "../../api/Offset";
import { useFootprint } from "../../api/Footprint";
import { useOffsetByProjects } from "../../api/OffsetByProjects";
import { CardHeaderWithToolTip } from "../CardHeader";

const AVERAGE_NYC_TO_LONDON_CARBON_TONNES_EMITTED = 1.7;
const AVERAGE_CAR_PER_MILE_CARBON_TONNES_EMITTED = 0.000404;
const AVERAGE_POUND_OF_COAL_CARBON_TONNES_EMITTED = 9.01 * 10 ** -4;

export function ImpactSection() {
  const offsetByCountry = useOffsetByCountry();
  const footprintData = useFootprint();
  const offsetData = useOffset();
  const offsetByProjects = useOffsetByProjects();

  const totalOffset =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(offsetData[0].total_offset).toLocaleString();
    }, [offsetData]) || 0;

  const totalOffsetInFlights =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(
        offsetData[0].total_offset / AVERAGE_NYC_TO_LONDON_CARBON_TONNES_EMITTED
      ).toLocaleString();
    }, [offsetData]) || 0;

  const totalOffsetInPoundsOfCoalBurnt =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(
        offsetData[0].total_offset / AVERAGE_POUND_OF_COAL_CARBON_TONNES_EMITTED
      ).toLocaleString();
    }, [offsetData]) || 0;

  const totalOffsetInMilesDriven =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(
        offsetData[0].total_offset / AVERAGE_CAR_PER_MILE_CARBON_TONNES_EMITTED
      ).toLocaleString();
    }, [offsetData]) || 0;

  if (!offsetByCountry || !offsetData || !footprintData || !offsetByProjects) {
    return <></>;
  }
  return (
    <>
      <Typography variant="h1" color="primary.main">
        #impact 💪
      </Typography>
      <Typography variant="h2">on-chain offsets, off-chain impacts</Typography>
      <Grid container alignItems="center" textAlign={"center"} spacing={4}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 3, backgroundColor: "#FFFCFA" }}>
            <CardHeader
              titleTypographyProps={{
                variant: "h3",
                textAlign: "center",
                pt: 5,
              }}
              title={"Lifetime Carbon Offset"}
            />
            <CardContent>
              <Typography variant={"h4"} textAlign={"center"}>
                {totalOffset} tCO2
              </Typography>
              <Typography sx={{ p: 1, mb: 4 }}>
                That's equivalent to offsetting:
              </Typography>
              <Grid container spacing={1} mb={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Box
                    border={1}
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    borderColor={"divider"}
                    borderRadius={2}
                    pt={2}
                    pb={2}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FlightIcon sx={{ marginRight: "0.5rem" }} />
                      <Typography variant="h3" sx={{ color: "green" }}>
                        {totalOffsetInFlights}
                      </Typography>
                    </Box>
                    <Typography
                      component="span"
                      sx={{ display: "block", marginTop: "0.5rem" }}
                    >
                      Flights between London and NYC
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Box
                    border={1}
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    borderColor={"divider"}
                    borderRadius={2}
                    pt={2}
                    pb={2}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <DirectionsCarFilledOutlinedIcon
                        sx={{ marginRight: "0.5rem" }}
                      />
                      <Typography variant="h3" sx={{ color: "green" }}>
                        {totalOffsetInMilesDriven}
                      </Typography>
                    </Box>
                    <Typography
                      component="span"
                      sx={{ display: "block", marginTop: "0.5rem" }}
                    >
                      Miles driven by car
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box
                    border={1}
                    textAlign="center"
                    alignItems="center"
                    justifyContent="center"
                    borderColor={"divider"}
                    borderRadius={2}
                    pt={2}
                    pb={2}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LocalFireDepartmentOutlinedIcon
                        sx={{ marginRight: "0.5rem" }}
                      />
                      <Typography variant="h3" sx={{ color: "green" }}>
                        {totalOffsetInPoundsOfCoalBurnt}
                      </Typography>
                    </Box>
                    <Typography
                      component="span"
                      sx={{ display: "block", marginTop: "0.5rem" }}
                    >
                      Pounds of coal burned
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            elevation={1}
            sx={{
              borderRadius: 3,
              backgroundColor: "#FFFCFA",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <CardContent>
                <CardHeaderWithToolTip
                  header={" Global Impact"}
                  tooltip={"Location of Celo’s offset projects"}
                />
              </CardContent>
            </Box>
            <Card
              sx={{
                backgroundColor: "#FFFCFA",
                borderTop: "1px solid black",
                borderColor: "divider",
                borderRadius: 0,
                justifyContent: "center",
                display: "flex",
              }}
            >
              <CardContent sx={{ width: "90%", pt: 1 }}>
                <WorldMap data={offsetByCountry} />
              </CardContent>
            </Card>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card
            sx={{ height: "100%", backgroundColor: "#FFFCFA", borderRadius: 3 }}
          >
            <Card
              sx={{
                borderTop: 1,
                borderColor: "divider",
                borderRadius: 0,
                backgroundColor: "#FFFCFA",
              }}
            >
              <CardContent>
                <TransactionsTable offsetsByProject={offsetByProjects} />
              </CardContent>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
