import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type CeloTransactionFees = {
  data: Array<{ date: string; v: number }>;
};

const url = "/tx_fees";

export const useCeloTransactionFees = (): Array<{
  date: string;
  v: number;
}> => {
  const { data } = useSWR<CeloTransactionFees>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });
  console.log("tx fees", data);

  return data?.data!;
};
