import React from "react";
import { Box, createTheme, ThemeProvider, useMediaQuery } from "@mui/material";

import { UltragreenSection } from "../components/Sections/UltraGreenSection";
import Header from "../components/Sections/Header";
import { CarbonOffsetFund } from "../components/Sections/CarbonOffsetFund";
import { ImpactSection } from "../components/Sections/ImpactSection";
import { BurnSection } from "../components/Sections/BurnSection";
import { SocialSection } from "../components/Sections/SocialSection";
import { ContactSection } from "../components/Sections/ContactSection";
import { LearnMoreSection } from "../components/Sections/LearnMoreSection";
import { GaugeSection } from "../components/Sections/GaugeSection";
import { PartnerSection } from "../components/Sections/PartnerSection";

export function Dashboard() {
  const isMobile = useMediaQuery("(max-width:1200px)");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#34C25B",
        light: "#DBFBE5",
      },
      secondary: {
        main: "#9E9A97",
        light: "#FFFCFA",
      },
    },
    typography: {
      fontFamily: "Inter",

      //section heading
      h1: {
        fontFamily: "Inter",
        fontSize: "36px",
        fontWeight: 600,
        lineHeight: "44px",
        letterSpacing: "-0.02em",
        textAlign: "center",
        fontStyle: "semi-bold",
        color: "#34C25B",
        paddingTop: "8rem",
        paddingBottom: "1rem",
      },
      // section subheading
      h2: {
        fontFamily: "Inter",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "38px",
        letterSpacing: "-0.01em",
        textAlign: "center",
        marginBottom: "2rem",
        paddingBottom: "1rem",
        color: "#393939",
      },
      // cards heading
      h3: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 500,
        textAlign: "left",
        lineHeight: "30px",
        letterSpacing: "-0.01em",
      },
      // updated 42 seconds ago
      subtitle1: {
        fontFamily: "Inter",
        fontSize: isMobile ? "10px" : "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "-0.1px",
        textAlign: "left",
        color: "#7C7977",
      },

      // cards green values
      h4: {
        fontFamily: "Inter",
        fontSize: "36px",
        fontWeight: 600,
        lineHeight: "44px",
        letterSpacing: "-0.02em",
        textAlign: "left",
        color: "#34C25B",
        marginLeft: "0.5rem",
      },

      h5: {
        fontFamily: "Inter",
        fontSize: "32px",
        color: "#34C25B",
        letterSpacing: "-2%",
      },
      body1: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#9E9A97",
      },
      body2: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#9E9A97",
        marginLeft: "0.5rem",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ml: "5%",
          mr: "5%",
          mt: isMobile ? "4%" : "2%",
          pb: isMobile ? 4 : 8,
        }}
      >
        <Header />
      </Box>
      <Box
        sx={{
          ml: "5%",
          mr: "5%",
          mb: 20,
        }}
      >
        <UltragreenSection />
        <GaugeSection />
        <ImpactSection />
        <CarbonOffsetFund />
        <BurnSection />
        <PartnerSection />
        <SocialSection />
        <LearnMoreSection />
        <ContactSection />
      </Box>
    </ThemeProvider>
  );
}
