import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type OffsetProjectType = {
  project: string;
  url: string;
  countries: string[];
  methodologies: string[];
  offsets: number[];
};

export type OffsetProjectsResponseType = {
  data: OffsetProjectType[];
};

const url = "/offset_projects";

export const useOffsetByProjects = (): OffsetProjectType[] | undefined => {
  const { data } = useSWR<OffsetProjectsResponseType>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  return data?.data;
};
