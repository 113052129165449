import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

import { CeloDailyTxFeeChart } from "../Charts/CeloDailyTxFeeChart";
import { useCeloTransactionFees } from "../../api/CeloTransactionFees";

export function BurnSection() {
  const celoTransactionFees = useCeloTransactionFees();
  if (!celoTransactionFees) return <></>;

  return (
    <>
      <Typography variant="h1" color={"#E51220"}>
        #burn 🔥
      </Typography>
      <Typography variant="h2">CELO, not the environment</Typography>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} lg={12}>
          <Card sx={{ borderRadius: 2, backgroundColor: "#FFFCFA" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <CardContent>
                <Typography variant={"h3"}>
                  Celo Mainnet Total Transaction Fees
                </Typography>
              </CardContent>
            </Box>
            <Card
              sx={{
                borderRadius: 0,
                backgroundColor: "#FFFCFA",
                borderTop: 1,
                borderColor: "divider",
                pt: 3,
              }}
            >
              <CeloDailyTxFeeChart
                data={{ transactionFees: celoTransactionFees }}
              />
            </Card>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
