export type DateTimeString = string;

export const timeFrames = ["hour", "day", "week", "month", "year"] as const;
export type TimeFrame = (typeof timeFrames)[number];

export const displayTimeFrameToHour: Record<TimeFrame, number> = {
  hour: 1,
  day: 24,
  week: 168,
  month: 730,
  year: 8760,
};

const getArrayWithPastMonths = (numOfMonths: number): string[] => {
  const currentDate = new Date(); // get current date
  return Array.from(Array(numOfMonths)).map((_, i) => {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    return date.toISOString().slice(0, 10);
  });
};

export const getYearMonthsFromToday = (numOfMonths: number): string[] => {
  return getArrayWithPastMonths(numOfMonths).map((dateString) => {
    const date = new Date(
      Date.UTC(
        parseInt(dateString.substring(0, 4)),
        parseInt(dateString.substring(5, 7)),
        1
      )
    );
    const year = date.getUTCFullYear().toString(); // extract last two digits of year
    const month = date.toLocaleString("default", { month: "short" }); // format month as three-letter abbreviation
    return `${month} ${year}`;
  });
};
