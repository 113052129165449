import React from "react";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";

import { ToolTip } from "../ToolTip";
import BaseGauge from "../Gauges/BaseGauge";
import { useGaugeValues } from "../../api/GaugeValues";

export const GaugeSection = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const gaugeValues = useGaugeValues();

  if (!gaugeValues) {
    return <></>;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card
          sx={{
            backgroundColor: "#FFFCFA",
            borderRadius: 3,
            p: 1,
            mt: 4,
          }}
        >
          <Grid item container xs={12} textAlign={"center"}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pt: 3,
                  pl: 3,
                  pr: 3,
                }}
              >
                <Typography sx={{ fontSize: 18, color: "black" }} mr={1}>
                  Year to Date
                </Typography>
                <ToolTip tip="Total Offset, Footprint, Net since the beginning of the current year. Relative indicators are snap shots of what the YTD metrics were in other years e.g. 2022 = YTD this month/day in 2022" />
              </Box>
            </Grid>
            {isMobile ? (
              <Grid item xs={12}>
                <BaseGauge
                  min={gaugeValues.offset.min}
                  max={gaugeValues.offset.max}
                  actual={gaugeValues.offset.value}
                  primaryColor={"#74ED96"}
                  secondaryColor={"#DBFBE5"}
                  title={"Offset"}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={4}>
                  <BaseGauge
                    min={gaugeValues.offset.min}
                    max={gaugeValues.offset.max}
                    actual={gaugeValues.offset.value}
                    primaryColor={"#74ED96"}
                    secondaryColor={"#DBFBE5"}
                    title={"Offset"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BaseGauge
                    min={gaugeValues.footprint.min}
                    max={gaugeValues.footprint.max}
                    actual={gaugeValues.footprint.value}
                    primaryColor={"#FF616B"}
                    secondaryColor={"#FFAFB5"}
                    title={"Footprint"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BaseGauge
                    min={gaugeValues.net.min}
                    max={-gaugeValues.net.max}
                    actual={gaugeValues.net.value}
                    primaryColor={"#CC5BFF"}
                    secondaryColor={"#E5AAFF"}
                    title={"Net Impact"}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
