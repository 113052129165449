import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Typography } from "@mui/material";

import { getYearMonthsFromToday } from "../../Utilities/time";

export const GreenFundChartLegend = () => {
  return (
    <>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={14} height={14} bgcolor="#34C25B" borderRadius={1} mr={1} />
        <Typography variant="subtitle2">epoch distribution</Typography>
      </Box>
      <Box display="flex" alignItems="center" mr={2}>
        <Box width={14} height={14} bgcolor="#C25B34" borderRadius={1} mr={1} />
        <Typography variant="subtitle2">validator donation</Typography>
      </Box>
    </>
  );
};

interface ChartData {
  date: string;
  source: string;
  v: number;
}

interface EpochChartData {
  epoch: ChartData[];
}

const createChartOptions = (
  epochData: ChartData[],
  donationData: number[]
): Highcharts.Options => {
  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  const epochPlotData = [...epochData].reverse().slice(-24);
  const formattedDates = getYearMonthsFromToday(epochPlotData.length).reverse();

  return {
    chart: {
      height: "275px",
      type: "column",
      backgroundColor: "#FFFCFA",
    },
    title: {
      text: "",
    },
    yAxis: {
      gridLineDashStyle: "LongDash",
      gridLineColor: "#EADDD3",
      title: {
        text: "CELO",
      },
    },
    xAxis: {
      categories: formattedDates,
      plotLines: [
        {
          color: "gray",
          dashStyle: "Dot",
          value: 30,
          width: 1,
          zIndex: 1,
          label: {
            text: "CGP-08X passed (Toucan)",
            rotation: 0,
            x: -40,
            y: -10,
            textAlign: "left",
            style: {
              fontSize: "16px",
              color: "#8F8F8F",
            },
          },
        },
      ],
    },
    plotOptions: {
      column: {
        stacking: "normal",
        color: "#34C25B",
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "10px",
          },
        },
      },
    },
    series: [
      {
        type: "column",
        name: "donations",
        data: donationData,
        color: "rgba(52, 194, 91, 0.5)", // set lighter shade of green
        stack: 0,
      },
      {
        type: "column",
        name: "epoch rewards",
        data: epochPlotData.map((d) => Math.ceil(d.v / 1e18)),
        stack: 0,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

export const GreenFundChart = ({ epoch }: EpochChartData): JSX.Element => {
  const donationData = [0, 0, 0, 0, 0, 0];
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={createChartOptions(epoch, donationData)}
    />
  );
};
