import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";

import { TwitterProfile, useTwitterProfiles } from "../../api/TwitterProfiles";
import { getDomain } from "../../config";

export function SocialSection() {
  const twitterAuthenticationUrl = getDomain() + "/auth/twitter";

  const twitterProfiles = useTwitterProfiles();

  const handleCopy = () => {
    navigator.clipboard.writeText("🦇🌳");
  };

  const isMobile = useMediaQuery("(max-width:1200px)");

  return (
    <>
      <Typography variant="h1" color="primary.main">
        Join {twitterProfiles?.count ?? ""} members
      </Typography>
      <Typography variant="h2">on-chain offsets, off-chain impact</Typography>
      <Grid
        container
        alignItems="center"
        textAlign={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={12} sm={10}>
          <Grid container justifyContent="center">
            {twitterProfiles ? (
              twitterProfiles.profiles
                .slice(0, isMobile ? 20 : 84)
                .map((profile: TwitterProfile, index) => (
                  <Grid
                    item
                    key={index}
                    xs={3}
                    md={2}
                    lg={1}
                    p={1}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <a
                      href={`https://twitter.com/${profile.screen_name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="avatar-link"
                    >
                      <Avatar
                        src={profile.profile_pic_url.replace("_normal", "")}
                        alt={profile.name}
                        sx={{
                          width: 50,
                          height: 50,
                          boxShadow: 10,
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                      />
                    </a>
                  </Grid>
                ))
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Card sx={{ borderRadius: 3, backgroundColor: "#FFFCFA", mt: 15 }}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography variant="h1" color="primary.main" sx={{ pt: 5 }}>
              join the twitter fam
            </Typography>
          </Grid>
          <Box>
            <Grid container item xs={12} spacing={1} justifyContent="center">
              <Box>
                <Grid item xs={12} pt={4} alignItems="center">
                  <Typography
                    variant="body2"
                    color="secondary.main"
                    align="center"
                  >
                    <b>First</b> add the UltraGreen emojis 👇 to your Twitter
                    screen name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  pt={2}
                  alignItems="center"
                  textAlign={"center"}
                >
                  <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        backgroundColor: "ultragreen",
                        height: 24,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 1,
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        borderRadius: 10,
                      }}
                      onClick={handleCopy}
                    >
                      <Box
                        sx={{
                          color: "white",
                          textTransform: "none",
                          borderRadius: 10,
                          backgroundColor: "#F2E9E2",
                          fontSize: 20,
                          pl: 1,
                          pr: 1,
                          mr: 1,
                          ml: -1,
                        }}
                      >
                        🦇🌳
                      </Box>
                      <Typography color="black">copy</Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} pt={4} alignItems="center">
                  <Typography
                    variant="body2"
                    color="secondary.main"
                    align="center"
                  >
                    <b>Then</b> Authenticate 👇
                  </Typography>
                </Grid>
              </Box>
            </Grid>
          </Box>
          <Grid
            item
            xs={12}
            pb={5}
            pt={2}
            alignItems="center"
            textAlign={"center"}
          >
            <Button
              variant={"contained"}
              sx={{ color: "white", textTransform: "none" }}
              href={twitterAuthenticationUrl}
            >
              <TwitterIcon /> &nbsp;Authenticate
            </Button>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
