import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type GaugesResponseType = {
  data: {
    "1YR": {
      footprint: { value: number; min: number; max: number };
      offset: { value: number; min: number; max: number };
      net: { value: number; min: number; max: number };
    };
  };
};

const url = "/gauges";

export const useGaugeValues = (): {
  footprint: { value: number; min: number; max: number };
  offset: { value: number; min: number; max: number };
  net: { value: number; min: number; max: number };
} => {
  const { data } = useSWR<GaugesResponseType>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  console.log("gauges", data);
  return data?.data["1YR"];
};
