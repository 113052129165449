import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type Footprint = {
  data: Array<{ date: string; v_non_c: number; v: number }>;
};

const url = "/footprint";

export const useFootprint = (): Array<{
  date: string;
  v_non_c: number;
  v: number;
}> => {
  const { data } = useSWR<Footprint>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  return data?.data!;
};
