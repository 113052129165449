import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type OffsetByCountryType = {
  data: Array<{
    project: string;
    countries: number;
    methodologies: string[];
    offsets: number[];
  }>;
};

const url = "/offset_by_country";

export const useOffsetByCountry = (): Array<{
  project: string;
  countries: number;
  methodologies: string[];
  offsets: number[];
}> => {
  const { data } = useSWR<OffsetByCountryType>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  return data?.data!;
};
