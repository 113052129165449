import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { OffsetProjectType } from "../../api/OffsetByProjects";
import { getColorByString } from "../Colors";

function createData(
  project: string,
  offsets: number[],
  countries: string[],
  methodologies: string[]
) {
  const roundedOffsets = offsets.map((offset) => Math.round(offset));
  return {
    project,
    offsets: roundedOffsets,
    countries,
    methodologies,
  };
}

interface MethodologyTextProps {
  methodology: string;
}

const MethodologyText: React.FC<MethodologyTextProps> = ({ methodology }) => {
  if (methodology.length > 15) {
    const shortenedMethodology = methodology.slice(0, 15) + "...";
    return (
      <Tooltip title={methodology} arrow placement="top">
        <span>{shortenedMethodology}</span>
      </Tooltip>
    );
  }
  return <span>{methodology}</span>;
};

interface TransactionsTableProps {
  offsetsByProject: OffsetProjectType[];
}

function Row(props: { row: ReturnType<typeof createData>; index: number }) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={row.project}
        sx={{
          "& > *": {
            borderBottom:
              row.methodologies.length > 1 ? "transparent" : "transparent",
          },
        }}
      >
        <TableCell>
          <Box display="flex" alignItems="center">
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: getColorByString(row.project),
              }}
            />
            <Box sx={{ pl: 1 }}>
              {row.project.charAt(0).toUpperCase() + row.project.slice(1)}{" "}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
            {row.offsets.reduce((acc, curr) => acc + curr, 0).toLocaleString()}
            <Typography sx={{ fontColor: "black", fontSize: "12px", ml: 1 }}>
              tC02
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {row.countries.length > 1 ? <>Multiple</> : row.countries}
        </TableCell>
        <TableCell>
          {row.methodologies.length > 1 ? (
            <>Multiple</>
          ) : (
            <MethodologyText methodology={row.methodologies[0]} />
          )}
        </TableCell>
        {row.methodologies.length > 1 ? (
          <TableCell>
            <>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </>
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      <TableRow key={row.project}>
        <TableCell
          sx={{
            pb: 0,
            pt: 0,
            pl: 0,
            pr: 0,
            margin: 0, // Set margin to zero
            padding: 0, //
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box pr={8} pb={3}>
              <Table>
                <TableBody sx={{ fontSize: "12px" }}>
                  {row.countries.map((country, index) => (
                    <>
                      {row.offsets[index] > 0 && (
                        <TableRow key={country} sx={{ margin: 0, padding: 0 }}>
                          <TableCell
                            sx={{
                              width: "25%",
                              borderBottom: "none",
                              pb: 0,
                            }}
                          />
                          <TableCell
                            sx={{
                              width: "25%",
                              borderBottom: "none",
                              pb: 0,
                            }}
                          >
                            <Typography>
                              <Box
                                sx={{
                                  display: "inline-flex",
                                  alignItems: "baseline",
                                }}
                              >
                                {row.offsets[index].toLocaleString()}
                                <Typography
                                  sx={{
                                    fontColor: "black",
                                    fontSize: "12px",
                                    ml: 1,
                                  }}
                                >
                                  tC02
                                </Typography>
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "25%",
                              borderBottom: "none",
                              pb: 0,
                            }}
                          >
                            <Typography>{country}</Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "25%",
                              borderBottom: "none",
                              pb: 0,
                            }}
                          >
                            <Typography>
                              <MethodologyText
                                methodology={row.methodologies[index]}
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function TransactionsTable({
  offsetsByProject,
}: TransactionsTableProps) {
  const rows = offsetsByProject.map((project) =>
    createData(
      project.project,
      project.offsets,
      project.countries,
      project.methodologies
    )
  );
  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        width: "100%",
      }}
    >
      <Table aria-label="collapsible table" sx={{ borderBottom: 0 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "25%", border: 0 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Project
              </Typography>
            </TableCell>
            <TableCell sx={{ width: "25%", border: 0 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Offset Total
              </Typography>
            </TableCell>
            <TableCell sx={{ width: "25%", border: 0 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Countries
              </Typography>
            </TableCell>
            <TableCell sx={{ width: "25%", border: 0 }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Methodology
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ pb: 1 }}>
          {rows.map((row, index) => (
            <>
              <Row key={row.project} row={row} index={index} />
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
