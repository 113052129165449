import React, { useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { Box, Tooltip, Typography } from "@mui/material";
import Zoom from "@mui/material/Zoom";

import features from "./features.json";
import { OffsetByCountryType } from "../../api/OffsetByCountry";
import { CoordinatesTable, CountryCoordinates } from "./CoordinatesTable";

export const WorldMapLegend = () => {
  return (
    <Typography display="flex" alignItems="center">
      <Box width={14} height={14} bgcolor="#56DF7C" borderRadius={1} mr={1} />
      <Typography variant="subtitle2">Offset Project</Typography>
    </Typography>
  );
};

export function WorldMap(data: OffsetByCountryType) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [hoverContent, setHoverContent] = useState<string>("");

  const handleMouseEnter = (event: any, content: string) => {
    setAnchorEl(event.currentTarget);
    setHoverContent(content);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setHoverContent("");
  };

  const markers: { coordinates: CountryCoordinates; offset: number }[] =
    data.data
      .filter(
        (countryData: any) =>
          CoordinatesTable[countryData.country] !== undefined
      )
      .map((countryData: any) => {
        const countryName: string = countryData.country;
        const countryCoordinates: CountryCoordinates =
          CoordinatesTable[countryName];
        return { coordinates: countryCoordinates, offset: countryData.offset };
      });

  const mapWidth = 900;
  const mapHeight = 400;

  const offsetMin = Math.min(...markers.map(({ offset }) => offset));
  const offsetMax = Math.max(...markers.map(({ offset }) => offset));

  const markerSizeMin = 5;
  const markerSizeMax = 20;

  const scale = (offset: number) => {
    const logOffset = Math.log10(offset - offsetMin + 1);
    const logRange = Math.log10(offsetMax - offsetMin + 1);
    const sizeRange = markerSizeMax - markerSizeMin;
    return markerSizeMin + sizeRange * (logOffset / logRange);
  };

  return (
    <Box>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          center: [0, 45],
          scale: 100,
        }}
        width={mapWidth}
        height={mapHeight}
        viewBox={`0 0 ${mapWidth} ${mapHeight}`}
        style={{ display: "block" }}
      >
        <Geographies geography={features}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: "#A6E1A8",
                    fillOpacity: 0.35,
                    stroke: "#FFFAF6",
                    strokeWidth: 0.75,
                    outline: "none",
                  },
                  hover: {
                    fill: "#A6E1A8",
                    fillOpacity: 0.9,
                    strokeWidth: 2,
                    outline: "none",
                  },
                }}
              />
            ))
          }
        </Geographies>
        {markers.map((m) => (
          <Marker
            coordinates={[m.coordinates.longitude, m.coordinates.latitude]}
          >
            <defs>
              <radialGradient
                id="circleGradient"
                cx="50%"
                cy="50%"
                r="50%"
                fx="50%"
                fy="50%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: "#56DF7C", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgba(86, 223, 124, 0.26)" }}
                />
              </radialGradient>
            </defs>
            <Tooltip
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 600 }}
              title={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Box>
                    Offset: {Math.round(m.offset).toLocaleString()} tCO2
                  </Box>
                  <Box>Country: {m.coordinates.country}</Box>
                </Box>
              }
            >
              <circle
                r={scale(m.offset)}
                fill="url(#circleGradient)"
                stroke="#56DF7C"
                strokeWidth={0}
              />
            </Tooltip>
          </Marker>
        ))}
      </ComposableMap>
    </Box>
  );
}
