import React, { useMemo, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { GreenFundChart, GreenFundChartLegend } from "../Charts/GreenFundChart";
import { BigToggle } from "../Toggles/BigToggle";
import { useElapsedTime } from "../Hooks/ElapssedTime";
import { useCeloUSDRate } from "../../api/CeloUSDRate";
import { useOffsetFundingSource } from "../../api/OffsetFundingSource";
import { ToolTip } from "../ToolTip";
import { CardHeaderWithToolTip } from "../CardHeader";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#34C25B",
  },
}));

export function CarbonOffsetFund() {
  const [currency, setCurrency] = useState("CELO");
  const isMobile = useMediaQuery("(max-width:600px)");

  const offsetFundingData = useOffsetFundingSource();
  const celoUsdRateValue = useCeloUSDRate();

  const totalOffsetValue =
    useMemo(() => {
      if (!offsetFundingData) return null;
      return Math.ceil(
        offsetFundingData.reduce((acc, curr) => acc + curr.v, 0) / 10 ** 18
      ).toLocaleString();
    }, [offsetFundingData]) || "0";

  const totalOffsetValueUSD =
    useMemo(() => {
      if (!offsetFundingData) return null;
      return Math.ceil(
        offsetFundingData.reduce((acc, curr) => acc + curr.v_usd, 0)
      ).toLocaleString();
    }, [offsetFundingData, celoUsdRateValue]) || "0";

  const elapsedTime = useElapsedTime([offsetFundingData, celoUsdRateValue]);

  if (!offsetFundingData || !celoUsdRateValue) return <></>;

  return (
    <>
      <Typography variant="h1" component="h2" color="primary.main">
        #carbon-offset-fund 💸
      </Typography>
      <Typography variant="h2">funding sources to offset carbon</Typography>

      <Grid container spacing={4} sx={{ height: "100%" }}>
        <Grid item xs={12} lg={8}>
          <Card
            elevation={1}
            sx={{ borderRadius: 3, backgroundColor: "#FFFCFA", height: "100%" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <CardContent>
                <Typography variant={"h3"}>Offset Funding Over Time</Typography>
              </CardContent>
              {!isMobile && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  alignSelf="flex-start"
                  pt={3}
                >
                  <GreenFundChartLegend />
                </Box>
              )}
            </Box>

            <Card
              elevation={0}
              sx={{
                backgroundColor: "#FFFCFA",
                borderTop: "1px solid black",
                borderColor: "divider",
                borderRadius: 0,
                width: "100%",
              }}
            >
              <CardContent>
                <GreenFundChart epoch={offsetFundingData} />
              </CardContent>
              {isMobile && (
                <Box display="flex" alignItems="center" pl={2} pb={1}>
                  <GreenFundChartLegend />
                </Box>
              )}
            </Card>
          </Card>
        </Grid>

        <Grid item container xs={12} lg={4} spacing={3}>
          <Grid item xs={12} height={"60%"}>
            <Card
              sx={{
                height: "100%",
                borderRadius: 3,
                backgroundColor: "#FFFCFA",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pr={2}
              >
                <CardContent>
                  <CardHeaderWithToolTip
                    header={"Lifetime Carbon Offset"}
                    tooltip={
                      "A total amount (in Celo) that have been attributed to Carbon Offset Fund"
                    }
                  />
                </CardContent>
              </Box>
              <CardContent sx={{ mt: 2 }}>
                <Typography variant="h4">
                  {currency === "USD" ? totalOffsetValueUSD : totalOffsetValue}
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "28px",
                      fontWeight: 400,
                      lineHeight: "38px",
                      letterSpacing: "-0.01em",
                      textAlign: "left",
                    }}
                    color="textSecondary"
                    component="span"
                  >
                    &nbsp;{currency === "USD" ? "USD" : "CELO"}
                  </Typography>
                </Typography>
              </CardContent>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: -2,
                }}
              >
                <Typography
                  variant="body2"
                  color="secondary.main"
                  fontSize={"16px"}
                >
                  Updated {elapsedTime}s seconds ago
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" color="textSecondary">
                    USD&nbsp;&nbsp;
                  </Typography>
                  <BigToggle
                    checked={currency === "CELO"}
                    onChange={() =>
                      setCurrency((prevCurrency) =>
                        prevCurrency === "USD" ? "CELO" : "USD"
                      )
                    }
                  />
                  <Typography variant="body2" color="textSecondary">
                    CELO
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} height={"40%"}>
            <Card
              sx={{
                height: "100%",
                borderRadius: 3,
                backgroundColor: "#FFFCFA",
              }}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant={"h3"} alignItems={"center"} mr={1}>
                    Celo Offset Funding Source
                  </Typography>
                  <ToolTip tip="Methods used to fund Carbon Offset Fund." />
                </Box>
              </CardContent>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <BorderLinearProgress variant="determinate" value={100} />
                  </Box>
                  <Box sx={{ width: "120px" }}>
                    <Typography color="secondary.main" fontWeight={"20px"}>
                      &nbsp;{100}% Epoch
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                  <Box style={{ flex: 1 }}>
                    <BorderLinearProgress variant="determinate" value={0} />
                  </Box>
                  <Box sx={{ width: "120px" }}>
                    <Typography color="secondary.main" fontWeight={"20px"}>
                      &nbsp;{0}% Donations
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
