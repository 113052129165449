import React, { useMemo, CSSProperties } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  OffsetVsFootprintChart,
  OffsetVsFootprintLegend,
} from "../Charts/OffsetVsFootprint";

import UltragreenLogo from "../../assets/UltragreenMoneyLogo.png";
import { useOffset } from "../../api/Offset";
import { useFootprint } from "../../api/Footprint";
import { useElapsedTime } from "../Hooks/ElapssedTime";
import { CardHeaderWithToolTip } from "../CardHeader";

const rotatedInspireStyle: CSSProperties = {
  position: "absolute",
  top: "-12%",
  left: "-22%",
  transform: "rotate(-20deg)",
  textAlign: "left",
};

const mobileInspireStyle: CSSProperties = {
  textAlign: "center",
  marginTop: "-40px",
};

export const UltragreenSection = () => {
  const offsetData = useOffset();
  const footprintData = useFootprint();
  const elapsedTime = useElapsedTime([offsetData, footprintData]);

  const isMobile = useMediaQuery("(max-width:600px)");
  const isFullScreen = useMediaQuery("(min-width: 1250px)");

  const totalNetCarbonFootprint =
    useMemo(() => {
      if (!offsetData || !footprintData) return null;
      return (-Math.ceil(
        offsetData[0].total_offset - footprintData[0].v
      )).toLocaleString();
    }, [offsetData, footprintData]) || 0;

  const totalOffset =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(offsetData[0].total_offset).toLocaleString();
    }, [offsetData]) || "0";

  const totalOffsetWren =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(offsetData[0].offset_wren).toLocaleString();
    }, [offsetData]) || "0";

  const totalOffsetToucan =
    useMemo(() => {
      if (!offsetData) return null;
      return Math.ceil(offsetData[0].offset_toucan).toLocaleString();
    }, [offsetData]) || "0";

  if (!offsetData || !footprintData) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          mb: 2,
          display: "flex",
          justifyContent: "center",
          ...(isMobile && { px: 4 }),
        }}
      >
        <Box
          sx={{
            position: "relative",
            maxWidth: "600px",
          }}
        >
          <img
            src={UltragreenLogo}
            alt="ultragreen money"
            style={{
              maxWidth: "100%",
            }}
          />
          <Typography
            sx={{
              ...(isFullScreen ? rotatedInspireStyle : mobileInspireStyle),
              fontFamily: "Comic Neue",
              fontSize: isMobile ? 12 : 16,
              fontWeight: 100,
              lineHeight: "14px",
              letterSpacing: "0em",
              textAlign: "center",
              mt: isMobile ? 2 : 0,
            }}
          >
            INSPIRED BY{" "}
            <a
              href="https://ultrasound.money/"
              target="_blank"
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              ULTRASOUND.MONEY
            </a>
            {isFullScreen && (
              <Typography sx={{ pt: 1, color: "black" }}>^</Typography>
            )}
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} lg={8}>
          <Card sx={{ borderRadius: 3, backgroundColor: "#FFFCFA" }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <CardContent>
                <CardHeaderWithToolTip
                  header={"Celo Carbon Footprint over Time"}
                  tooltip={
                    "Total carbon footprint of Celo vs carbon offsets since inception"
                  }
                />
                <Typography variant="subtitle1" mt={1}>
                  Updated {elapsedTime} seconds ago
                </Typography>
              </CardContent>
              {!isMobile && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  alignSelf="flex-start"
                  pt={3}
                >
                  <OffsetVsFootprintLegend />
                </Box>
              )}
            </Box>
            <Card
              sx={{
                borderRadius: 0,
                backgroundColor: "#FFFCFA",
                borderTop: 1,
                borderColor: "divider",
                pt: 3,
              }}
            >
              <OffsetVsFootprintChart
                offset={offsetData}
                footprint={footprintData}
              />
              {isMobile && (
                <Box display="flex" alignItems="center" pl={2} pb={1} pt={1}>
                  <OffsetVsFootprintLegend />
                </Box>
              )}
            </Card>
          </Card>
        </Grid>
        <Grid item container xs={12} lg={4} spacing={4}>
          <Grid
            item
            xs={12}
            height={"40%"}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Card
              sx={{
                borderRadius: 3,
                backgroundColor: "#FFFCFA",
                height: "100%",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pr={2}
              >
                <CardContent>
                  <CardHeaderWithToolTip
                    header={"Lifetime Net Carbon Footprint"}
                    tooltip={"Total CO2 Emitted minus(-) Total Offsetted CO2"}
                  />
                </CardContent>
              </Box>
              <Card
                elevation={3}
                sx={{
                  justifyContent: "center",
                  borderRadius: 3,
                  backgroundColor: "#FFFCFA",
                  height: "100%",
                }}
              >
                <CardHeader
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 2,
                        pb: 1,
                      }}
                    >
                      <Typography variant={"h4"} component="span">
                        {totalNetCarbonFootprint} tCO
                        <Typography
                          variant="subtitle1"
                          color={"#34C25B"}
                          component="span"
                        >
                          2
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "26px",
                          letterSpacing: "-0.1px",
                          textAlign: "right",
                        }}
                      >
                        NET
                      </Typography>
                    </Box>
                  }
                />
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} height={"60%"}>
            <Card
              sx={{
                borderRadius: 3,
                backgroundColor: "#FFFCFA",
                height: "100%",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pr={2}
              >
                <CardContent>
                  <Typography variant="h3" mr={1}>
                    Lifetime Net Carbon Offset
                  </Typography>
                </CardContent>
              </Box>

              <Card
                elevation={2}
                sx={{
                  borderRadius: 3,
                  backgroundColor: "#FFFCFA",
                  height: "100%",
                }}
              >
                <CardHeader
                  title={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                      }}
                    >
                      <Typography variant={"h4"} component="span">
                        {totalOffset} tCO
                        <Typography color={"#34C25B"} component="span">
                          2
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "26px",
                          letterSpacing: "-0.1px",
                          textAlign: "right",
                        }}
                      >
                        TOTAL
                      </Typography>
                    </Box>
                  }
                />

                {isMobile ? (
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      sx={{ m: 2, color: "#7C7977" }}
                    >
                      {totalOffsetToucan} tCO2 TOUCAN
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ m: 2, color: "#7C7977" }}
                    >
                      {totalOffsetWren} tCO2 WREN
                    </Typography>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                      <Box
                        component="span"
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "26px",
                          letterSpacing: "-0.1px",
                          textAlign: "left",
                          backgroundColor: "#DBFBE5",
                          borderRadius: 2,
                          color: "#454342",
                          p: 1.5,
                        }}
                      >
                        {totalOffsetToucan} tCO2 TOUCAN
                      </Box>
                    </Typography>
                    <Typography variant="h5">
                      <Box
                        component="span"
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "26px",
                          letterSpacing: "-0.1px",
                          textAlign: "left",
                          backgroundColor: "#DBFBE5",
                          borderRadius: 2,
                          color: "#454342",
                          p: 1.5,
                        }}
                      >
                        {totalOffsetWren} tCO2 WREN
                      </Box>
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
