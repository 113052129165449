import React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";

interface CustomGaugeProps extends CircularProgressProps {
  min: number;
  max: number;
  actual: number;
  primaryColor: string;
  secondaryColor: string;
  title: string;
}
const BaseGauge: React.FC<CustomGaugeProps> = ({
  min,
  max,
  actual,
  primaryColor,
  secondaryColor,
  title,
  ...other
}) => {
  const value = ((actual - min) / (max - min)) * 100;
  const gaugeValue = value > 100 ? 100 : value < 0 ? 0 : value;

  return (
    <Card
      elevation={0}
      sx={{ height: "100%", backgroundColor: "secondary.light" }}
    >
      <CardHeader
        titleTypographyProps={{
          variant: "h3",
          textAlign: "center",
        }}
        title={title}
      />
      <CardContent sx={{ paddingTop: 12 }}>
        <Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
            sx={{ transform: "rotate(-126deg)" }}
          >
            <CircularProgress
              variant="determinate"
              value={70}
              size={175}
              thickness={5}
              sx={{
                color: secondaryColor,
                zIndex: 1,
                position: "absolute",
              }}
              {...other}
            />
            <CircularProgress
              variant="determinate"
              value={gaugeValue * 0.5}
              size={175}
              thickness={5}
              sx={{
                color: primaryColor,
                zIndex: 2,
                position: "absolute",
              }}
              {...other}
            />
          </Box>
        </Box>
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "28px",
              fontWeight: 500,
              lineHeight: "36px",
              letterSpacing: "-0.02em",
              textAlign: "center",
              color: "#393939",
              pt: 10,
            }}
          >
            {Math.ceil(actual).toLocaleString()} tCO2
          </Typography>
          <Typography variant={"subtitle1"} textAlign={"center"}>
            0.2 tCO2 per transaction
          </Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
};

export default BaseGauge;
