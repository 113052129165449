import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";

import { fetchJsonSwr } from "./Fetchers";

export type OffsetDataType = {
  data: Array<{
    date: string;
    offset_wren: number;
    offset_toucan: number;
    total_offset: number;
    offset_wren_non_c: number;
    offset_toucan_non_c: number;
    total_offset_non_c: number;
  }>;
};

const url = "/offset";

export const useOffset = (): Array<{
  date: string;
  offset_wren: number;
  offset_toucan: number;
  total_offset: number;
  offset_wren_non_c: number;
  offset_toucan_non_c: number;
  total_offset_non_c: number;
}> => {
  const { data } = useSWR<OffsetDataType>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  console.log("offset", data);
  return data?.data!;
};
