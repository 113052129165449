import { useState, useEffect } from "react";

export const useElapsedTime = <T extends any[]>(dependencies: T) => {
  const [timestamp, setTimestamp] = useState(Date.now());
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    setTimestamp(Date.now());
    setElapsedTime(0);
  }, [...dependencies]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().getTime();
      const timeDiff = now - timestamp;
      const secondsDiff = Math.floor(timeDiff / 1000);
      setElapsedTime(secondsDiff);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timestamp]);

  return elapsedTime;
};
