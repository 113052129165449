import { secondsToMilliseconds } from "date-fns";
import useSWR from "swr";
import { fetchJsonSwr } from "./Fetchers";

export type TwitterProfile = {
  name: string;
  screen_name: string;
  followers: number;
  bio: string;
  profile_pic_url: string;
};

export type TwitterProfilesResponse = {
  profiles: TwitterProfile[];
  count: number;
};

const url = "/profiles";

export const useTwitterProfiles = (): TwitterProfilesResponse | undefined => {
  const { data } = useSWR<TwitterProfilesResponse>(url, fetchJsonSwr, {
    refreshInterval: secondsToMilliseconds(10),
  });

  return data;
};
