import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";

import CarbonFundProcessFlow from "../../assets/CarbonFundProcessFlow.png";

export function LearnMoreSection() {
  const methodologies = [
    {
      question: "Footprint Calculation",
      answer: (
        <div>
          <Box sx={{ fontStyle: "italic" }}>
            <p>
              Disclaimer: Calculating the carbon footprint of Celo is a complex
              task that involves numerous variables, including a range of
              factors such as energy consumption, scope, and validator hardware
              specifications.
            </p>
            <p>
              cLabs acknowledge that there are various methodologies for
              determining the Celo blockchain’s carbon footprint. cLabs has
              devised the methodology detailed below based on best efforts and
              accessible information, and will continue to work to improve the
              underlying carbon footprint calculation methodology.
            </p>
          </Box>
          <p>
            cLabs calculates the Celo blockchain’s carbon footprint based on the
            following methodology:
          </p>
          <ol>
            <li>
              Estimate the Total Amount of CO2 emitted in 2022 from all
              Validator Nodes on the Celo blockchain by extrapolating the 2022
              Google Cloud Platform (GCP) Carbon Emission Report from the
              following validators:
            </li>
            <li>
              Derive the unit metric TCO2 Emitted Per Gas using #1 and
              multiplying this value by the Total Gas Used on the Celo mainnet
              in 2022.
            </li>
            <li>
              Apply the TCO2 Emitted per Gas metric from #2 against real gas
              used to calculate the emissions going forward
            </li>
          </ol>
        </div>
      ),
    },
    {
      question: "Offset Calculation",
      answer: (
        <div>
          <p>
            The Celo blockchain’s carbon offsets are currently done through two
            mechanisms as determined by the Celo ecosystem through on-chain
            governance and facilitated by the Celo Carbon Neutrality Multisig
            (CCNM) whose members include representatives from Spirals Protocol,
            Mercy Corps Ventures, the Climate Collective, and the Celo
            Foundation:
            <ul className={"dashed"}>
              <li>
                <a href={"https://www.wren.co/profile/celo"}>Wren</a>: CCNM
                sends CELO each month to Wren to offset carbon
              </li>
              <li>
                <a href={"https://toucan.earth/"}>Toucan</a>: CCNM purchases and
                retires TCO2 tokens on the Toucan platform for projects a BBB
                rating or higher.
              </li>
            </ul>
          </p>
          <p>
            For more information, see{" "}
            <a href={"https://forum.celo.org/t/on-chain-offsets/5471"}>
              this post
            </a>{" "}
            on the Celo Forum.
          </p>
        </div>
      ),
    },
  ];

  const FAQs = [
    {
      question: "What is UltraGreen Money?",
      answer: (
        <Box>
          <Typography paragraph>
            Ultragreen Money introduced a refined Celo tokenomics and
            contributor rewards framework.
          </Typography>
          <Typography paragraph>
            The Ultragreen Proposal{" "}
            <a
              href={
                "https://forum.celo.org/t/core-devs-call-on-cip-ultragreen-celo/5387"
              }
            >
              CIP-52
            </a>{" "}
            introduces a revised structure for how base fees are utilized in
            which a portion is burned (similar to Ethereum) and another portion
            is used to purchase carbon offsets that align with Celo’s commitment
            to sustainability.
          </Typography>
        </Box>
      ),
    },
    {
      question: "What inspired the launch of UltraGreen?",
      answer: (
        <Box>
          <Typography paragraph>
            Since its inception, Celo has had close ties to Ethereum. Celo began
            as a fork of Ethereum, which was then optimized for mobile devices,
            usability, and a carbon neutral footprint. Looking forward, a key
            pillar of the{" "}
            <a
              href={
                "https://blog.celo.org/the-next-chapter-introducing-celo-2-0-by-clabs-b535db54a564"
              }
            >
              Celo 2.0 roadmap
            </a>{" "}
            is greater alignment to Ethereum.
          </Typography>
          <Typography paragraph>
            Ultragreen takes inspiration from the recent development of{" "}
            <a href={"https://ultrasound.money"}>Ultrasound Money</a> on
            Ethereum
          </Typography>
          <Typography paragraph>
            Ultrasound money is an Ethereum meme focusing on the likely decrease
            of ETH supply based on Ethereum’s base fee burning mechanism
          </Typography>
          <Typography paragraph>
            The name Ultrasound comes as a comparison to capped-supply assets,
            like gold and Bitcoin, which are labeled as sound. Having a
            decreasing-supply makes ETH ‘ultrasound.’
          </Typography>
        </Box>
      ),
    },
    {
      question: "How is UltraGreen different from Ultrasound?",
      answer: (
        <Box>
          <Typography paragraph>
            <a
              href={
                "https://forum.celo.org/t/core-devs-call-on-cip-ultragreen-celo/5387"
              }
            >
              CIP-52
            </a>{" "}
            is inspired around building upon Ethereum’s Ultrasound Money to
            further Celo’s{" "}
            <a
              href={
                "https://github.com/celo-org/governance/blob/main/CGPs/cgp-0001.md"
              }
            >
              commitment to sustainability
            </a>
            .
          </Typography>
          <Typography paragraph>
            While Celo follows suit in burning a portion of each transaction
            like Ethereum’s Ultrasound Money, Ultragreen Money will also direct
            an additional portion of each transaction towards the purchase and
            retirement of carbon offsets{" "}
            <a
              href={
                "https://github.com/celo-org/governance/blob/main/CGPs/cgp-0001.md"
              }
            >
              selected through governance
            </a>{" "}
            by the Celo ecosystem.
          </Typography>
          <Typography paragraph>
            In short,{" "}
            <a
              href={
                "https://forum.celo.org/t/core-devs-call-on-cip-ultragreen-celo/5387"
              }
            >
              CIP-52
            </a>{" "}
            makes it so that every Celo transaction supports climate positive
            action!
          </Typography>
        </Box>
      ),
    },
    {
      question: "When will Celo become Ultragreen Money?",
      answer: (
        <Box>
          <Typography paragraph>
            Celo Ultragreen is currently deployed on the Cannoli testnet!
          </Typography>
          <Typography paragraph>
            With the passing of the ultragreen governance proposal (
            <a
              href={
                "https://github.com/celo-org/governance/blob/main/CGPs/cgp-0077.md"
              }
            >
              CGP-77
            </a>
            ), the next step towards adopting the UltraGreen changes is for
            validators to choose to follow an upcoming hard fork. This is
            targeted for Summer 2023.
          </Typography>
          <Typography paragraph>
            The hardfork will take all changes currently on the Cannoli Testnet
            and bring them to mainnet.
          </Typography>
        </Box>
      ),
    },
    {
      question: "What is a carbon tonne? How much is it really?",
      answer: (
        <Box>
          <Typography paragraph>
            A carbon tonne is a common measurement unit for carbon emissions and
            sequestration.
          </Typography>
          <Typography paragraph>
            To make it tangible, approximately{" "}
            <a
              href={
                "https://www.climateneutralgroup.com/en/news/what-exactly-is-1-tonne-of-co2/"
              }
            >
              50 trees
            </a>{" "}
            must grow for one year to offset 1 tCO2.
          </Typography>
        </Box>
      ),
    },
    {
      question: "Is CELO deflationary?",
      answer: (
        <Box>
          <Typography paragraph>
            The UltraGreen governance proposal would position Celo to become
            deflationary.
          </Typography>
          <Typography paragraph>
            CELO has a total supply cap of 1 billion. If validators accept the
            hard fork making Celo UltraGreen, every CELO burnt with transaction
            fees will reduce the supply cap.
          </Typography>
          <Typography paragraph>
            The Celo protocol's daily{" "}
            <a href="https://docs.celo.org/protocol/pos/epoch-rewards">
              Epoch Rewards
            </a>{" "}
            mints CELO to reward validators, validator groups, and stakers, as
            well provides ongoing funding to the on-chain Community Fund and
            Carbon Offset Fund. Epoch Rewards follow an exponentially decreasing
            curve until the supply cap is met.
          </Typography>
          <Typography paragraph>
            Once the amount of CELO burnt daily through transaction fees exceeds
            the amount of CELO minted through Epoch Rewards, CELO would be
            considered to be deflationary.
          </Typography>
        </Box>
      ),
    },
    {
      question: "What is the Carbon Offset Fund? How does it work?",
      answer: (
        <Box>
          <Typography paragraph>
            Celo's Carbon Offset Fund is a proposed designated multi-signature
            address, created with the passing of{" "}
            <a
              href={
                "https://github.com/celo-org/governance/blob/main/CGPs/cgp-0077.md"
              }
            >
              CGP-77
            </a>
            .
          </Typography>
          <Typography paragraph>
            This address would serve the role of accruing funds from daily Epoch
            Rewards and Celo transaction fees and attributing them to purchase
            high quality carbon offsets.
          </Typography>
          <Typography paragraph>
            Historically, the Celo ecosystem has used{" "}
            <a href={"https://www.wren.co/profile/celo"}>Wren</a> to offset
            carbon,{" "}
            <a
              href={
                "https://github.com/celo-org/governance/blob/main/CGPs/cgp-0077.md"
              }
            >
              CGP-77
            </a>{" "}
            expands the Celo ecosystem’s climate action to include the use of
            on-chain assets.
          </Typography>
          <Typography paragraph>
            The below diagram outlines how a multi-signature account could
            purchase and retire carbon credits from both Wren and the{" "}
            <a href={"https://toucan.earth/"}>Toucan Protocol.</a>
            <Box
              sx={{
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={CarbonFundProcessFlow}
                alt={"Carbon Fund Process Diagram"}
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 5,
                  objectFit: "cover",
                }}
              />
            </Box>
            Carbon credits would initially be purchased and retired utilizing
            both on and off chain mechanisms. The longer-term vision of the Celo
            ecosystem is to transition more of this to on-chain solutions that
            ensure transparency, efficacy, and quality. With the growth of
            organizations such as <a href={"https://toucan.earth"}>Toucan</a>{" "}
            and <a href={"https://www.carbonpath.io"}>CarbonPath</a>, the Celo
            ecosystem will be able to make this vision a reality.
          </Typography>
          <Typography paragraph>
            To learn more, see the full governance proposal{" "}
            <a href={"https://forum.celo.org/t/on-chain-offsets/5471"}>here</a>.
          </Typography>
        </Box>
      ),
    },
    {
      question: "How can I explore the Cannoli Testnet?",
      answer: (
        <Box>
          <Typography paragraph>
            Check out the Cannoli Testnet using the following links
            <ul>
              <li>
                {" "}
                Visit the Cannoli{" "}
                <a href={"https://blockscout.cannoli.celo-testnet.org/"}>
                  explorer
                </a>
              </li>
              <li>
                Leverage the{" "}
                <a href={"https://blockscout.api.cannoli.celo-testnet.org"}>
                  explorer API
                </a>
              </li>
              <li>
                Connect using the Cannoli{" "}
                <a href={"https://forno.cannoli.celo-testnet.org"}>
                  RPC endpoint
                </a>
              </li>
            </ul>
            Use this in code or pass it to{" "}
            <a href={"https://docs.celo.org/cli"}>celocli</a> with --node
          </Typography>
          <Typography paragraph>
            Get funds using the{" "}
            <a href={"https://faucet.celo.org/cannoli"}>Cannoli Token Faucet</a>
          </Typography>
        </Box>
      ),
    },
    {
      question: "How are the footprints calculated?",
      answer: (
        <Box>
          <Typography paragraph>See Methodology 👆</Typography>
        </Box>
      ),
    },
    {
      question: "What is cLabs?",
      answer: (
        <Box>
          <Typography paragraph>
            <a href={"https://clabs.co"}>cLabs</a> is a leading contributor to
            the Celo technical stack.
          </Typography>
          <Typography paragraph>
            Since Celo’s 2020 mainnet launch, the cLabs team has made
            significant strides in making Celo the leading platform for
            Regenerative Finance (
            <a
              href={
                "https://je.mirror.xyz/S-dpms92hw6aiacUHoL3f_iAnLVDvbEUOXw7wpy7JaU"
              }
            >
              ReFi
            </a>
            ) builders, including –
          </Typography>
          <ul>
            <li>
              Incubating notable projects such as{" "}
              <a href={"https://valoraapp.com/"}>Valora</a>,{" "}
              <a href={"https://www.hyperlane.xyz"}>Hyperlane</a>, and{" "}
              <a href={"https://mento.finance/"}>Mento</a>
            </li>
            <li>
              Launching initiatives, including{" "}
              <a href={"https://app.stcelo.xyz/connect"}>StakedCelo</a>,
              <a href={"https://github.com/celo-org/SocialConnect"}>
                {" "}
                SocialConnect
              </a>
              , and the{" "}
              <a href={"https://explorer.celo.org/mainnet/"}>Celo Explorer</a>
            </li>
            <li>
              Supporting thousands of Celo ReFi projects currently building on
              Celo including{" "}
              <a href={"https://www.carbonpath.io/"}>CarbonPath</a>, &nbsp;
              <a href={"https://www.carbontitle.com/"}>Carbon Title</a>, &nbsp;
              <a href={"https://www.ethichub.com/en/"}>EthicHub</a>
              <a href={"https://www.flowcarbon.com/"}>Flowcarbon</a>, &nbsp;
              <a href={"https://docs.celo.org/"}>Gainforest</a>, &nbsp;
              <a href={"https://www.gooddollar.org/"}>GoodDollar</a>, and &nbsp;
              <a href={"https://www.masa.finance/"}>Masa Finance</a>
            </li>
          </ul>
        </Box>
      ),
    },
    {
      question: "Where can I learn more about building on Celo?",
      answer: (
        <Box>
          <Typography paragraph>
            Sign up for Celo Signal -{" "}
            <a href="https://share.hsforms.com/1Qrhush1vSA2WIamd_yL4ow53n4j?__hstc=45198203.98fd07e0fce9f4b134c7b4ea5ab0bd01.1681481202088.1681484783663.1681493791447.3&__hssc=45198203.1.1681493791447&__hsfp=3339576864">
              Mailing List
            </a>
          </Typography>
          <Typography paragraph>
            Join the Community – <a href="https://discord.gg/celo">Discord</a>
          </Typography>
          <Typography paragraph>
            Key Announcements –{" "}
            <a href="https://twitter.com/CeloOrg">@CeloOrg</a>,{" "}
            <a href="#https://twitter.com/cLabs">@cLabs</a>
          </Typography>
          <Typography paragraph>
            Build with Celo –{" "}
            <a href="https://docs.celo.org/">Celo Developer</a>
          </Typography>
        </Box>
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState("faq");
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box fontFamily={"Roboto"}>
      <Box
        sx={{
          pt: 5,
          pb: 5,
        }}
      >
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1">
              <Box
                color={selectedTab === "faq" ? "primary.main" : "#9E9A97"}
                onClick={() => setSelectedTab("faq")}
                sx={{ cursor: "pointer" }}
              >
                FAQ
              </Box>
            </Typography>
            <Typography variant="h1" color="#9E9A97">
              |
            </Typography>
            <Typography variant="h1">
              <Box
                color={
                  selectedTab === "methodology" ? "primary.main" : "#9E9A97"
                }
                onClick={() => setSelectedTab("methodology")}
                sx={{ cursor: "pointer" }}
              >
                Methodology
              </Box>
            </Typography>
          </Box>
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={5.5}>
              <Typography variant="h1" sx={{ textAlign: "end" }}>
                <Box
                  color={selectedTab === "faq" ? "primary.main" : "#9E9A97"}
                  onClick={() => setSelectedTab("faq")}
                  sx={{ cursor: "pointer" }}
                >
                  FAQ
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={0.5}>
              <Typography
                variant="h1"
                color="#9E9A97"
                sx={{ textAlign: "center" }}
              >
                |
              </Typography>
            </Grid>
            <Grid item xs={5.5}>
              <Typography variant="h1" sx={{ textAlign: "start" }}>
                <Box
                  color={
                    selectedTab === "methodology" ? "primary.main" : "#9E9A97"
                  }
                  onClick={() => setSelectedTab("methodology")}
                  sx={{ cursor: "pointer" }}
                >
                  Methodology
                </Box>
              </Typography>
            </Grid>
          </Grid>
        )}
      </Box>

      {selectedTab === "faq" && (
        <>
          {FAQs.map((faq, index) => (
            <Accordion
              key={index}
              disableGutters={true}
              sx={{
                backgroundColor: "#FFFCFA",
                pt: 1,
                pl: 1,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ cursor: "pointer" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    fontSize: 18,
                    color: "#575552",
                  }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0, marginTop: "-1px", p: 2 }}>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Roboto", fontSize: 16, color: "#706b68" }}
                >
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
      {selectedTab === "methodology" && (
        <Box>
          {methodologies.map((method, index) => (
            <Accordion
              key={index}
              disableGutters={true}
              sx={{
                backgroundColor: "#FFFCFA",
                pt: 1,
                pl: 1,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ cursor: "pointer" }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", fontSize: 18, color: "#575552" }}
                >
                  {method.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0, marginTop: "-1px", p: 2 }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 16, color: "#706b68" }}
                >
                  {method.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Box>
  );
}
